/* Tailwind base contains a reset which can overwrite Mantine styles. To avoid
/* this, we add it to a layer which will fall last in the priority order. Due to
/* Next not consistently respecting style import order between builds/envs the
/* layer order is set in a <script> tag in the root layout.tsx */
@layer tw-base {
  @tailwind base;
}

/* Utilities and components are the tailwind styles that we (the devs) add as
/* classes. Left outside of a layer they are treated as having a higher priority
/* and are able to overwrite both mantine and tailwind base styles. */
@tailwind utilities;
@tailwind components;

/* Any future third party layers can be added here alongside Mantine. When new
/* layers are added ensure that their order is set in the root layout.tsx */
@import "@mantine/core/styles.layer.css";
@import "@mantine/dates/styles.layer.css";
@import "@mantine/charts/styles.layer.css";
@import "@mantine/notifications/styles.layer.css";
@import "@fortawesome/fontawesome-svg-core/styles.css";
